(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("zlib"));
	else if(typeof define === 'function' && define.amd)
		define("dicom-parser", ["zlib"], factory);
	else if(typeof exports === 'object')
		exports["dicom-parser"] = factory(require("zlib"));
	else
		root["dicomParser"] = factory(root["zlib"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE_zlib__) {
return 